import React, { useEffect, useState } from "react";
import "./App.css";

import { useSearchParams } from "react-router-dom";
import { RequestType, SelectedItem } from "./RequestType";
import { Request } from "./Request";
import China from "./page/China/China";
import Turkey from "./page/Turkey/Turkey";
import { ScrollingHandler } from "./handler/ScrollingHandler";

function App() {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const serviceId = searchParams.get("serviceId");
  const [request, setRequest] = useState<RequestType | null>(null)
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([])
  const [isObligatory, setIsObligatory] = useState<boolean>(false)
  const [postRequestStatus, setPostRequestStatus] = useState<Request>(
    Request.Init
  )
  const [getRequestStatus, setGetRequestStatus] = useState<Request>(
    Request.Init
  )

  const getServiceInterface = ()=>{
      if(serviceId){
          switch(serviceId){
            case 'ab826150-c32e-4dad-abcd-8c8b5c37def0':
            case '01081210-0c75-44fb-adc8-ef35f8dea572':
              return <Turkey 
              isObligatory={isObligatory}
              keyID={key}
              request={request} 
              getRequestStatus={getRequestStatus} 
              postRequestStatus={postRequestStatus} 
              selectedItems={selectedItems} 
              sendData={sendData}
              setSelectItems={(items, item)=>setSelectedItems([...items, item])}/>
            default:
              return <China 
              isObligatory={isObligatory}
              keyID={key}
              request={request} 
              getRequestStatus={getRequestStatus} 
              postRequestStatus={postRequestStatus} 
              selectedItems={selectedItems} 
              sendData={sendData}
              setSelectItems={(items, item)=>setSelectedItems([...items, item])}/>
          }
      }
      else{
        return <div className="no-access">Unable to access, please contact support</div>
      }
  }

  useEffect(() => {
    if(key){
      setGetRequestStatus(Request.Pending);
      fetch("https://api.unitrade.space/v1/sellers/returns?key=" + key)
        .then((response) => response.json())
        .then((data) => {
          setRequest(data);
          setGetRequestStatus(Request.Success);
        });
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendData = async () => {
    if (selectedItems.length === 0) return;
    if(selectedItems.filter(x=>(x.returnType !== 1) && (!x.deliveryInfo?.recipientName || !x.deliveryInfo?.recipientPhoneNumber || !x.deliveryInfo?.address)).length > 0){
        setIsObligatory(true)
        ScrollingHandler()
        
        return
    }
    setPostRequestStatus(Request.Pending);
    // const rawResponse =
    await fetch(
      "https://api.unitrade.space/v1/sellers/returns?key=" + key,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ returns: selectedItems }),
      }
    );
    fetch("https://api.unitrade.space/v1/sellers/returns?key=" + key)
      .then((response) => response.json())
      .then((data) => {
        setRequest(data);
        setPostRequestStatus(Request.Success);
      });
  };

  return (
    <div className="app">
        {getServiceInterface()}
    </div>
  );
}

export default App;
