import {useEffect, useState,useRef} from 'react'
import './AddressForm.css'
import { language } from '../../content/text'
import { region } from '../../content/region'
import Select from 'react-select';
import { GetAddressForm } from '../../RequestType';
interface AddressFormProps {
    languageId?:number
    onChange:(sendData:GetAddressForm)=>void
    variant:number
    isObligatory:boolean
}
const AddressForm = (props:AddressFormProps)=>{

    const [phoneNumber, setPhoneNumber] = useState<string|null>(null)
    const [name, setName] = useState<string|null>(null)
    const [comment, setComment] = useState<string|null>(null)
    const [address, setAddress] = useState<string|null>(null)
    const [postCode, setPostCode] = useState<string|null>(null)
    const [city, setCity] = useState<string|null>(null)
    const [area, setArea] = useState<any>(null)
    const inputRefs = useRef<(HTMLInputElement | HTMLTextAreaElement| null)[]>([]);

    const scrollToEmptyInput = () => {
      for (let i = 0; i < inputRefs.current.length; i++) {
        const input = inputRefs.current[i];
        if (input && input.value === '') {
          input.scrollIntoView({ behavior: 'auto' , block:'center'});
          break;
        }
      }
    }

    let leng;

    switch(props.languageId)
    {
        case 1:
            leng = language.china
            break
        case 2:
            leng = language.turkey
            break
        default: 
            leng = language.english
            break
    }
    useEffect(()=>{             
        const sendAddress = { recipientPhoneNumber:phoneNumber, recipientName:name,
           address, comment, zipCode:postCode, city , region:area }
        props.onChange(sendAddress)
        if(props.isObligatory) scrollToEmptyInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [phoneNumber, name, address, comment, area, city, postCode, props.isObligatory])

  
    return (
      <form className={props.isObligatory && (!name || !phoneNumber || !address) ? 'order-form order-form--isError' : 'order-form'}>
        
        <label className='order-form__text'>
        {leng.addressForm.name}   
          <input  ref={ref => inputRefs.current[0] = ref}  className={props.isObligatory && !name ? 'order-form__input order-form__input--isError' : 'order-form__input'} type="text" value={name ?? ''} onChange={(e) => { setName(e.target.value)}} />
        </label>
        <label className='order-form__text'>
        {leng.addressForm.phoneNumber}
          <input  ref={ref => inputRefs.current[0] = ref} className={props.isObligatory && !phoneNumber ? 'order-form__input order-form__input--isError' : 'order-form__input'} type="text" value={phoneNumber ?? ''} onChange={(e) => { setPhoneNumber(e.target.value)}} />
        </label>
        {props.variant === 2 ?   <>
              <div className='order-form__column'>
                
                  <label className='order-form__text'>
                  {leng.addressForm.area}
                    <Select onChange={(value)=> setArea(value?.value)} classNamePrefix={'order-form-select'} placeholder={leng.addressForm.placeholder} options={region.map(item=>{ return {value:item.name, label:item.name} })} />
                  </label>

              </div>
              
              <label className='order-form__text'>
              {leng.addressForm.city}
                <input className='order-form__input' type="text" value={city??''} onChange={(e) => { setCity(e.target.value)}} />
              </label>
              <label className='order-form__text'>
              {leng.addressForm.postalCode}
                <input className='order-form__input' type="text" value={postCode??''} onChange={(e) => { setPostCode(e.target.value)}} />
              </label>
        </> :'' }
        <div className='order-form__column'>
          <label className='order-form__text'>
          {leng.addressForm.address}
            <textarea  ref={ref => inputRefs.current[0] = ref}   className={props.isObligatory && !address ? 'order-form__input order-form__input--isError' : 'order-form__input'}  value={address ??''} onChange={(e) => { setAddress(e.target.value)}} />
          </label>
          <label className='order-form__text'>
          {leng.addressForm.comment}
            <textarea className='order-form__input'  value={comment ??''} onChange={(e) => { setComment(e.target.value)}} />
          </label>
        </div>
       
       
      </form>
    )
}
export default AddressForm