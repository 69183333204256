export const language = {
    turkey:{
        addressForm:{
            country:'Ülke',
            city:'Şehir',
            street:'Sokak',
            buildingNumber:'Bina numarası',
            apartmentNumber:'Apartman numarası',
            postalCode:'Posta kodu',
            name:'İsim',
            phoneNumber:'Telefon numarası',
            address:'Adres',
            comment:'Bir yorum',
            area:'Alan',
            placeholder:'Seçme'
        }
    },
    china:{
        addressForm:{
            country:'一个国家',
            city:'城市',
            street:'街道',
            buildingNumber:'房子',
            apartmentNumber:'公寓号',
            postalCode:'邮编',
            name:'姓名',
            phoneNumber:'电话',
            address:'地址',
            comment:'备注',
            area:'区域',
            placeholder:'选择'
        }
    },
    english:{
        addressForm:{
            country:'Country',
            city:'City',
            street:'Street',
            buildingNumber:'Building Number',
            apartmentNumber:'Apartment Number',
            postalCode:'ZIP Code',
            name:'Name',
            phoneNumber:'Phone number',
            address:'Address',
            comment:'Bir yorum',
            area:'Area',
            placeholder:'Select'
        }
    }
}