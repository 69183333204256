import './China.css'
import OrderChina from "../../components/OrderChina/OrderChina"
import Button from "../../components/Button/Button"
// import Warring from "../../components/Warning/Warning"
import { RequestType, SelectedItem } from '../../RequestType'
import { Request } from '../../Request'
import AlertSuccess from '../../components/AlertSuccess/AlertSuccess'
interface ChinaProps{
    request:RequestType | null
    keyID:string|null
    getRequestStatus:Request
    postRequestStatus:Request
    selectedItems:SelectedItem[]
    setSelectItems:(items:SelectedItem[], item:SelectedItem)=>void
    sendData:()=>void
    isObligatory:boolean
}
const China = ({request, keyID, isObligatory,getRequestStatus, postRequestStatus, selectedItems,setSelectItems, sendData}:ChinaProps)=>{
    return(<>
 {keyID ? <>  <div className="app__title">{request?.sellerName}</div>
      {postRequestStatus === Request.Pending ||
      getRequestStatus === Request.Pending ? (
        <div className={"app__loader"}>
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
         {/* <Warning/> */}
         <AlertSuccess/>
         <div>
          {request?.returns.length === 0 ? (
            <div>提交成功！ 暂时没有新的需要处理的退件.</div>
          ) : (
            <div>
              <div className="app__orders">
                {request?.returns.map((order,i) => (
                  <OrderChina
                    isObligatory={isObligatory}
                    key={i}
                    id={order.id}
                    amount={order.declaredValue.amount}
                    weight={order.weight.value}
                    items={order.items}
                    isPaid={order.isPaid}
                    trackNumber={order.trackNumber}
                    orderNumber={order.externalNumber}
                    onChange={(item) => {
                      const items = selectedItems.filter(
                        (x) => x.parcelId !== item.parcelId
                      );
                      setSelectItems(items, item)
                    //   setSelectedItems([...items, item]);
                    }}
                  />
                ))}
              </div>
              <div className={"app__button"}>
                <Button content='提交' onClick={sendData} />
              </div>
            </div>
          )}
        </div>
        </>
       
      )}</>: <div className="no-access">無法訪問，請聯繫支持</div> }
    </>)
}
export default China