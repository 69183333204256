import './Turkey.css'
import OrderTurkey from '../../components/OrderTurkey/OrderTurkey'
import Button from "../../components/Button/Button"
// import Warring from "../../components/Warring/Warring"
import { RequestType, SelectedItem } from '../../RequestType'
import { Request } from '../../Request'
interface TurkeyProps{
    request:RequestType | null
    keyID:string|null
    getRequestStatus:Request
    postRequestStatus:Request
    selectedItems:SelectedItem[]
    setSelectItems:(items:SelectedItem[], item:SelectedItem)=>void
    sendData:()=>void
    isObligatory:boolean
}
const Turkey = ({request,isObligatory, keyID, getRequestStatus, postRequestStatus, selectedItems,setSelectItems, sendData}:TurkeyProps)=>{
    return(<>
 {keyID ? <>  <div className="app__title">{request?.sellerName}</div>
      {postRequestStatus === Request.Pending ||
      getRequestStatus === Request.Pending ? (
        <div className={"app__loader"}>
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
         {/* <Warring/> */}
         <div>
          {request?.returns.length === 0 ? (
            <div>Başarıyla gönderildi! Şu anda işlenecek yeni iade yok.</div>
          ) : (
            <div>
              <div className="app__orders">
                {request?.returns.map((order,i) => (
                  <OrderTurkey
                    isObligatory={isObligatory}
                    key={i}
                    id={order.id}
                    amount={order.declaredValue.amount}
                    weight={order.weight.value}
                    items={order.items}
                    trackNumber={order.trackNumber}
                    orderNumber={order.externalNumber}
                    onChange={(item) => {
                      const items = selectedItems.filter(
                        (x) => x.parcelId !== item.parcelId
                      );
                      setSelectItems(items, item)
                    }}
                  />
                ))}
              </div>
              <div className={"app__button"}>
                <Button content='Değerlendirmeye sunmak' onClick={sendData} />
              </div>
            </div>
          )}
        </div>
        </>
       
      )}</>: <div className="no-access">Erişilemiyor, lütfen desteğe başvurun</div> }
    </>)
}
export default Turkey