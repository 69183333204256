import React, {  useState } from "react";
// import Poll from "../Poll/Poll";
import "./OrderTurkey.css";
import {
  faHashtag,
  faMoneyBill1Wave,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetAddressForm, OrderItem, SelectedItem } from "../../RequestType";
import classNames from "classnames";
import AddressForm from "../AddressForm/AddressForm";

interface OrderProps {
  weight: number;
  amount: number;
  items: OrderItem[];
  trackNumber: string;
  orderNumber: string;
  onChange: (item: SelectedItem) => void;
  id: string;
  isObligatory:boolean
}

const OrderTurkey = (props: OrderProps) => {
  const [variant, setVariant] = useState<number>(0);
  const [sendData, setSendData] = useState<GetAddressForm>({ address:null, city:null, comment:null, recipientName:null,
    recipientPhoneNumber:null, region:null, zipCode:null});

  return (
    <div className={"order"}>
      <div className={"order__head"}>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faHashtag} />
          {props.trackNumber}
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faHashtag} />
          {props.orderNumber}
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faWeightHanging} /> {props.weight} g
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faMoneyBill1Wave} /> {props.amount} rub
        </div>
      </div>
      <div className={"order__body"}>
        {props.items.map((item, i) => (
          <div key={i} className={"order__product"}>
            <a href={item.url} rel="noreferrer" target="_blank" >
              {item.name}
            </a>
          </div>
        ))}
        <div className={"order__poll"}>
          <div className={"poll"}>
            <div className={"poll__text"}>Lütfen iade yöntemini seçin</div>
            <div className={"poll__variants"}>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 1,
                })}
                onClick={() => {
                  setVariant(1);
                  setSendData({ address:null, city:null, comment:null, recipientName:null,
                    recipientPhoneNumber:null, region:null, zipCode:null});
                  props.onChange({
                    parcelId: props.id,
                    returnType: 1,
                  });
                }}
              >
                1.	Siparişi ücretsiz imha etmek (Destroy packages for free)
              </div>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 2,
                })}
                onClick={() => {
                  setVariant(2);
                  props.onChange({
                    deliveryInfo:sendData,
                    parcelId: props.id,
                    returnType: 2,
                  });
                }}
              >
                 2.	Siparişi satıcının Rusya'daki adresine iade etmek (We return the seller's package to an address in Russia provided by the seller)
              </div>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 3,
                })}
                onClick={() => {
                  setVariant(3);
                  props.onChange({
                    deliveryInfo:sendData,
                    parcelId: props.id,
                    returnType: 3,
                  });
                }}
              >
                 3.	Siparişi Türkiye'ye iade etmek (Return the package to Turkey)
             
              </div>
            </div>
            {(variant === 2 || variant === 3) && (
              <>
                <div className={"poll__text"}>
                  {variant === 2 && (
                    <>
                      <span>Lütfen alıcının adı ve telefon numarası da dahil olmak üzere Türkçe adresi Rusça olarak doldurunuz:</span>
                      <div className={"description"}>
                      <AddressForm isObligatory={props.isObligatory} variant={variant} languageId={2} onChange={(sendData) => {
                        setSendData(sendData)
                        props.onChange({
                          deliveryInfo:sendData,
                          parcelId: props.id,
                          returnType: variant,
                        });
                      }}/>
                       
                      </div>
                    </>
                  )}
                  {variant === 3 && (
                      <>
                        <span>Lütfen Türkçe adresi, alıcının adı ve telefon numarası da dahil olmak üzere Türkçe olarak doldurunuz:</span>
                        <div className={"description"}>
                        <AddressForm  isObligatory={props.isObligatory} variant={variant} languageId={2} onChange={(sendData) => {
                        setSendData(sendData)
                        props.onChange({
                          deliveryInfo:sendData,
                          parcelId: props.id,
                          returnType: variant,
                        });
                      }}/>
                        </div>
                      </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTurkey;
