import React, {  useState } from "react";
// import Poll from "../Poll/Poll";
import "./OrderChina.css";
import {
  faHashtag,
  faMoneyBill1Wave,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetAddressForm, OrderItem, SelectedItem } from "../../RequestType";
import classNames from "classnames";
import AddressForm from "../AddressForm/AddressForm";
import { imageChina } from "./imageBase64China";
import { imageRuss } from "./imageBase64Russ";

interface OrderProps {
  weight: number;
  amount: number;
  items: OrderItem[];
  trackNumber: string;
  orderNumber: string;
  onChange: (item: SelectedItem) => void;
  id: string;
  isObligatory:boolean,
  isPaid: boolean
  
}

const OrderChina = (props: OrderProps) => {
  const [variant, setVariant] = useState<number>(0);
  
  const [sendData, setSendData] = useState<GetAddressForm>({ address:null, city:null, comment:null, recipientName:null,
    recipientPhoneNumber:null, region:null, zipCode:null});
  return (
    <div className={"order"}>
      <div className={"order__head"}>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faHashtag} />
          {props.trackNumber}
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faHashtag} />
          {props.orderNumber}
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faWeightHanging} /> {props.weight} g
        </div>
        <div className={"order__head-item"}>
          <FontAwesomeIcon icon={faMoneyBill1Wave} /> {props.amount} rub
        </div>
      </div>
      <div className={"order__body"}>
        {props.items.map((item, i) => (
          <div key={i} className={"order__product"}>
            <a href={item.url} rel="noreferrer" target="_blank" >
              {item.name}
            </a>
          </div>
        ))}
        <div className={"order__poll"}>
          <div className={"poll"}>
            <div className={"poll__text"}>请选择退件解决的方法</div>
            <div className={"poll__variants"}>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 1,
                })}
                onClick={() => {
                  setVariant(1);
                  setSendData({ address:null, city:null, comment:null, recipientName:null,
                    recipientPhoneNumber:null, region:null, zipCode:null});
                  props.onChange({
                    parcelId: props.id,
                    returnType: 1,
                  });
                }}
              >
                免费扔掉包裹
              </div>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 2,
                })}
                onClick={() => {
                  setVariant(2);
                  props.onChange({
                    deliveryInfo:sendData,
                    parcelId: props.id,
                    returnType: 2,
                  });
                }}
              >
                包裹发到俄罗斯任何地址.
              </div>
              <div
                className={classNames({
                  poll__variant: true,
                  "poll__variant--select": variant === 3,
                })}
                onClick={() => {
                  setVariant(3);
                  props.onChange({
                    deliveryInfo:sendData,
                    parcelId: props.id,
                    returnType: 3,
                  });
                }}
              >
                包裹退回中国.
              </div>
            </div>
            {(variant === 2 || variant === 3) && (
              <>
                <div className={"poll__text"}>
                  {variant === 2 && (
                    <>
                      <span>请用俄语填写俄罗斯地址，包括收货人姓名和电话：</span>
                      <div className={"description"}>
                      <AddressForm isObligatory={props.isObligatory} variant={variant} languageId={1} onChange={(sendData) => {
                        setSendData(sendData);
                        props.onChange({
                          deliveryInfo:sendData,
                          parcelId: props.id,
                          returnType: variant,
                        });
                      }}/>
                        {!props.isPaid && <div>
                          <div className={"warning"}>
                            <div>说明书:</div>
                            <div>
                              1.填写地址后请扫微信QR-code 并进行支付95人民币。请注意您必须添加备注和填写你支付的包裹运单号（UN…..CN）。 如果您没有备注运单号, 您付款将不会处理。
                            </div>
                            <div>
                              2.如果您有多个退的包裹，您必须进行每个包裹单独付款，禁止一次支付多个包裹的金额，此类付款将不会处理。
                            </div>
                            <div>
                              3.填写运单号和进行支付95元后请按”提交”的钮.
                            </div>
                            <div>
                              4. 您支付后我们在1月之内安排发送您的包裹到你提供的俄罗斯地址
                            </div>
                            <div>
                              <img className={"warning__img-chine"} alt="loding..." src={imageChina} />
                            </div>
                          </div>
                        </div>}
                        {props.isPaid && <div className={"alert-success"}><span className={"alert-success__text"}>这个包裹不需要支付退费、我们免费发送</span></div>}
                      </div>
                    </>
                  )}
                  {variant === 3 && (
                      <>
                        <span>请用中文填写中国地址包括收货人的姓名和电话号：</span>
                        <div className={"description"}>
                        <AddressForm isObligatory={props.isObligatory} variant={variant} languageId={1} onChange={(sendData) => {
                        setSendData(sendData)
                        props.onChange({
                           deliveryInfo:sendData,
                           parcelId: props.id,
                           returnType: variant,
                        });
                      }}/>
                          {!props.isPaid && <div>
                            <div className={"warning"}>
                               <div>说明书:</div>
                              <div>1.填写地址后请扫QR-code 并进行支付95人民币。请注意您必须添加备注和填写你支付的包裹运单号（UN…..CN）。 如果您没有备注运单号, 您付款将不会处理。</div>
                              <div>2.如果您有多个退的包裹，您必须进行每个包裹单独付款，禁止一次支付多个包裹的金额，此类付款将不会处理。</div>
                              <div>3.填写运单号和进行支付95元后请按”提交”的钮.</div>
                              <div>4. 您支付后我们在1月之内安排发送您的包裹到中国您手里。运输时效也1个月左</div>
                              <div>
                                <img className={"warning__img-russ"} alt="loading..." src={imageRuss} />
                              </div>
                            </div>
                          </div>}
                          {props.isPaid && <div className={"alert-success"}><span className={"alert-success__text"}>这个包裹不需要支付退费、我们免费发送</span></div>}
                        </div>
                      </>


                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderChina;
