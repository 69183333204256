export const region = [
        {
            "name": "Респ Адыгея",
            "type": "респ"
        },
        {
            "name": "Респ Башкортостан",
            "type": "респ"
        },
        {
            "name": "Респ Бурятия",
            "type": "респ"
        },
        {
            "name": "Респ Алтай",
            "type": "респ"
        },
        {
            "name": "Респ Дагестан",
            "type": "респ"
        },
        {
            "name": "Респ Ингушетия",
            "type": "респ"
        },
        {
            "name": "Кабардино-Балкарская Респ",
            "type": "респ"
        },
        {
            "name": "Респ Калмыкия",
            "type": "респ"
        },
        {
            "name": "Карачаево-Черкесская Респ",
            "type": "респ"
        },
        {
            "name": "Респ Карелия",
            "type": "респ"
        },
        {
            "name": "Респ Коми",
            "type": "респ"
        },
        {
            "name": "Респ Марий Эл",
            "type": "респ"
        },
        {
            "name": "Респ Мордовия",
            "type": "респ"
        },
        {
            "name": "Респ Саха /Якутия/",
            "type": "респ"
        },
        {
            "name": "Респ Северная Осетия - Алания",
            "type": "респ"
        },
        {
            "name": "Респ Татарстан",
            "type": "респ"
        },
        {
            "name": "Респ Тыва",
            "type": "респ"
        },
        {
            "name": "Удмуртская Респ",
            "type": "респ"
        },
        {
            "name": "Респ Хакасия",
            "type": "респ"
        },
        {
            "name": "Чеченская Респ",
            "type": "респ"
        },
        {
            "name": "Чувашская Республика - Чувашия",
            "type": "респ"
        },
        {
            "name": "Алтайский край",
            "type": "край"
        },
        {
            "name": "Краснодарский край",
            "type": "край"
        },
        {
            "name": "Красноярский край",
            "type": "край"
        },
        {
            "name": "Приморский край",
            "type": "край"
        },
        {
            "name": "Ставропольский край",
            "type": "край"
        },
        {
            "name": "Хабаровский край",
            "type": "край"
        },
        {
            "name": "Амурская обл",
            "type": "обл"
        },
        {
            "name": "Архангельская обл",
            "type": "обл"
        },
        {
            "name": "Астраханская обл",
            "type": "обл"
        },
        {
            "name": "Белгородская обл",
            "type": "обл"
        },
        {
            "name": "Брянская обл",
            "type": "обл"
        },
        {
            "name": "Владимирская обл",
            "type": "обл"
        },
        {
            "name": "Волгоградская обл",
            "type": "обл"
        },
        {
            "name": "Вологодская обл",
            "type": "обл"
        },
        {
            "name": "Воронежская обл",
            "type": "обл"
        },
        {
            "name": "Ивановская обл",
            "type": "обл"
        },
        {
            "name": "Иркутская обл",
            "type": "обл"
        },
        {
            "name": "Калининградская обл",
            "type": "обл"
        },
        {
            "name": "Калужская обл",
            "type": "обл"
        },
        {
            "name": "Камчатский край",
            "type": "край"
        },
        {
            "name": "Кемеровская область - Кузбасс",
            "type": "область"
        },
        {
            "name": "Кировская обл",
            "type": "обл"
        },
        {
            "name": "Костромская обл",
            "type": "обл"
        },
        {
            "name": "Курганская обл",
            "type": "обл"
        },
        {
            "name": "Курская обл",
            "type": "обл"
        },
        {
            "name": "Ленинградская обл",
            "type": "обл"
        },
        {
            "name": "Липецкая обл",
            "type": "обл"
        },
        {
            "name": "Магаданская обл",
            "type": "обл"
        },
        {
            "name": "Московская обл",
            "type": "обл"
        },
        {
            "name": "Мурманская обл",
            "type": "обл"
        },
        {
            "name": "Нижегородская обл",
            "type": "обл"
        },
        {
            "name": "Новгородская обл",
            "type": "обл"
        },
        {
            "name": "Новосибирская обл",
            "type": "обл"
        },
        {
            "name": "Омская обл",
            "type": "обл"
        },
        {
            "name": "Оренбургская обл",
            "type": "обл"
        },
        {
            "name": "Орловская обл",
            "type": "обл"
        },
        {
            "name": "Пензенская обл",
            "type": "обл"
        },
        {
            "name": "Пермский край",
            "type": "край"
        },
        {
            "name": "Псковская обл",
            "type": "обл"
        },
        {
            "name": "Ростовская обл",
            "type": "обл"
        },
        {
            "name": "Рязанская обл",
            "type": "обл"
        },
        {
            "name": "Самарская обл",
            "type": "обл"
        },
        {
            "name": "Саратовская обл",
            "type": "обл"
        },
        {
            "name": "Сахалинская обл",
            "type": "обл"
        },
        {
            "name": "Свердловская обл",
            "type": "обл"
        },
        {
            "name": "Смоленская обл",
            "type": "обл"
        },
        {
            "name": "Тамбовская обл",
            "type": "обл"
        },
        {
            "name": "Тверская обл",
            "type": "обл"
        },
        {
            "name": "Томская обл",
            "type": "обл"
        },
        {
            "name": "Тульская обл",
            "type": "обл"
        },
        {
            "name": "Тюменская обл",
            "type": "обл"
        },
        {
            "name": "Ульяновская обл",
            "type": "обл"
        },
        {
            "name": "Челябинская обл",
            "type": "обл"
        },
        {
            "name": "Забайкальский край",
            "type": "край"
        },
        {
            "name": "Ярославская обл",
            "type": "обл"
        },
        {
            "name": "г Москва",
            "type": "г"
        },
        {
            "name": "г Санкт-Петербург",
            "type": "г"
        },
        {
            "name": "Еврейская Аобл",
            "type": "Аобл"
        },
        {
            "name": "Ненецкий АО",
            "type": "АО"
        },
        {
            "name": "Ханты-Мансийский Автономный округ - Югра",
            "type": "АО"
        },
        {
            "name": "Чукотский АО",
            "type": "АО"
        },
        {
            "name": "Ямало-Ненецкий АО",
            "type": "АО"
        },
        {
            "name": "Респ Крым",
            "type": "респ"
        },
        {
            "name": "г Севастополь",
            "type": "г"
        },
        {
            "name": "г Байконур",
            "type": "г"
        }
    ]

