import React from "react";
import "./Button.css";

interface ButtonProps {
  onClick: () => void;
  content:string
}

const Button = (props: ButtonProps) => {
  return (
    <button onClick={props.onClick} className={"button"}>
      {props.content}
    </button>
  );
};

export default Button;
